import { API_URL } from "../constants/apiConstants";
import Axios from "axios";
import Decoder from "jwt-decode";
import {
  INVOICE_CHEQUE_KEY_VALUE_LIST_FAIL,
  INVOICE_CHEQUE_KEY_VALUE_LIST_REQUEST,
  INVOICE_CHEQUE_KEY_VALUE_LIST_SUCCESS,
  INVOICE_DETAILS_FAIL,
  INVOICE_DETAILS_REQUEST,
  INVOICE_DETAILS_SUCCESS,
  PAYMENT_CREATE_FAIL,
  PAYMENT_CREATE_REQUEST,
  PAYMENT_CREATE_SUCCESS,
  PAYMENT_DETAILS_FAIL,
  PAYMENT_DETAILS_REQUEST,
  PAYMENT_DETAILS_SUCCESS,
  PAYMENT_HISTORY_DETAILS_FAIL,
  PAYMENT_HISTORY_DETAILS_REQUEST,
  PAYMENT_HISTORY_DETAILS_SUCCESS,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_MODE_LIST_FAIL,
  PAYMENT_MODE_LIST_REQUEST,
  PAYMENT_MODE_LIST_SUCCESS,
  PAYMENT_UPDATE_FAIL,
  PAYMENT_UPDATE_REQUEST,
  PAYMENT_UPDATE_SUCCESS,
} from "../constants/paymentConstants";

export const listPayment = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/CustomerPayment/GetPaymentInfoGrid`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    dispatch({ type: PAYMENT_LIST_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: PAYMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPaymentByParam = (order, datas) => async (dispatch, getState) => {
  try {
    //console.log("Received datas in listPaymentByParam:", datas);
    dispatch({ type: PAYMENT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      method: "get",
      url: `${API_URL}/CustomerPayment/GetPaymentInfoGridByParam?startDate=${datas.startDate}&endDate=${datas.endDate}&customerId=${datas.customerId}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    //console.log("Axios config:", config);

    const { data } = await Axios(config);
   //console.log("API response data:", data);

    dispatch({
      type: PAYMENT_LIST_SUCCESS,
      payload: data.dataObj,
    });
    //console.log("Dispatched PAYMENT_LIST_SUCCESS with payload:", data.dataObj);

    return data;
  } catch (error) {
    //console.error("Error in listPaymentByParam:", error);
    dispatch({
      type: PAYMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPaymentMode = (value) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_MODE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/KeyValue/GetPaymentMethodKeyValue/${value}`,
    };
    const { data } = await Axios(config);
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.key,
        label: single.value,
      };
    });
    // console.log("final");
    // console.log(final);
    dispatch({
      type: PAYMENT_MODE_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: PAYMENT_MODE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// CustomerPayment / GetPaymentCustomerInvoice / 516;

export const listInvoiceOnlyValue = (id) => async (dispatch, getState) => {
  try {
    // console.log("asif id");
    // console.log(id);
    dispatch({ type: INVOICE_CHEQUE_KEY_VALUE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/CustomerPayment/GetPaymentCustomerInvoice/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.soSystemId,
        label: single.soSystemNo,
      };
    });
    //console.log("final");
    //console.log(final);
    dispatch({
      type: INVOICE_CHEQUE_KEY_VALUE_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: INVOICE_CHEQUE_KEY_VALUE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listChequeOnlyValue = (id) => async (dispatch, getState) => {
  try {
    // console.log("asif id");
    // console.log(id);
    dispatch({ type: INVOICE_CHEQUE_KEY_VALUE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/CustomerPayment/GetPaymentCustomerCheque/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await Axios(config);
    // console.log("data");
    // console.log(data);
    const final = data.dataObj.map((single) => {
      return {
        id: single.chequeInfoId,
        label: single.chequeNumber,
      };
    });
   // console.log("final");
    //console.log(final);
    dispatch({
      type: INVOICE_CHEQUE_KEY_VALUE_LIST_SUCCESS,
      payload: final.sort((a, b) => a.label.localeCompare(b.label)),
    });
  } catch (error) {
    dispatch({
      type: INVOICE_CHEQUE_KEY_VALUE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listInvoiceCheckKeyValue =
  (value) => async (dispatch, getState) => {
    try {
     // console.log("value");
     // console.log(value);
      dispatch({ type: INVOICE_CHEQUE_KEY_VALUE_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      var config = {
        method: "get",
        url: `${API_URL}/KeyValue/GetInvoiceAndChequeIdKeyValue/${value}`,
      };
      const { data } = await Axios(config);
      // console.log(data);
      const final = data.dataObj.map((single) => {
        return {
          id: single.key,
          label: single.value,
        };
      });
      //console.log("final");
      //console.log(final);
      dispatch({
        type: INVOICE_CHEQUE_KEY_VALUE_LIST_SUCCESS,
        payload: final.sort((a, b) => a.label.localeCompare(b.label)),
      });
    } catch (error) {
      dispatch({
        type: INVOICE_CHEQUE_KEY_VALUE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listInvoiceDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: INVOICE_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/CustomerPayment/GetPaymentInvoiceMetaDataById/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    // console.log(data[0]);

    dispatch({ type: INVOICE_DETAILS_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: INVOICE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createPayment = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "post",

      url: `${API_URL}/CustomerPayment/AddPaymentInfo`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: PAYMENT_CREATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: PAYMENT_CREATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });
    //console.log("finalGotData");
    //console.log(finalGotData);
    return finalGotData;
  } catch (error) {
    dispatch({
      type: PAYMENT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePayment = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "put",

      url: `${API_URL}/CustomerPayment/UpdatePaymentInfo`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    var finalGotData = await Axios(config)
      .then(function (response) {
        dispatch({
          type: PAYMENT_UPDATE_SUCCESS,
          payload: response.data.dataObj,
        });
        return response.data;
      })
      .catch(function (error) {
        dispatch({
          type: PAYMENT_UPDATE_FAIL,
          payload: error.response.data.errMsg,
        });
        return error.response.data;
      });

    return finalGotData;
  } catch (error) {
    dispatch({
      type: PAYMENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPaymentDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: PAYMENT_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",

      url: `${API_URL}/CustomerPayment/GetPaymentRelatedDetailsById/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    // console.log(data[0]);

    dispatch({ type: PAYMENT_DETAILS_SUCCESS, payload: data.dataObj });
  } catch (error) {
    dispatch({
      type: PAYMENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPaymentHistoryDetails = (id) => async (dispatch, getState) => {
  // console.log(id);
  try {
    dispatch({ type: PAYMENT_HISTORY_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    var config = {
      method: "get",
      url: `${API_URL}/CustomerPayment/GetPaymentRelatedDetailsById/231`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await Axios(config);

    // console.log("data[0]");
    // console.log(data);

    dispatch({ type: PAYMENT_HISTORY_DETAILS_SUCCESS, payload: data.dataObj });
    return data.dataObj;
  } catch (error) {
    dispatch({
      type: PAYMENT_HISTORY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
