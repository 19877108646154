import React, { useCallback, useState, } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {Grid,Paper,Radio,InputAdornment,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { Link, withRouter } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import {PaymentSchema} from "../../schemas";
import { createEmployee } from "../../redux/actions/employeeActions";
import { useEffect } from "react";
import Notification from "../../core/Notification";
import {
  listChannel,
  listDeparment,
  listDesignation,
} from "../../redux/actions/channelActions";

import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import {
  listCustomersForSignup,
  listEmployees,
  listEmployeesForDropdown,
} from "../../redux/actions/userActions";
import {
  createCheque,
  listBank,
  listChequeDetails,
  listChequeStatus,
  listChequeType,
} from "../../redux/actions/chequeActions";
import {
  CHEQUE_CREATE_RESET,
  CHEQUE_DETAILS_RESET,
} from "../../redux/constants/chequeConstants";
import {
  createPayment,
  listInvoiceCheckKeyValue,
  listInvoiceDetails,
  listPaymentDetails,
  listPaymentHistoryDetails,
  listPaymentMode,
  updatePayment,
} from "../../redux/actions/paymentActions";
import {
  INVOICE_DETAILS_RESET,
  PAYMENT_CREATE_RESET,
  PAYMENT_DETAILS_RESET,
  PAYMENT_HISTORY_DETAILS_RESET,
} from "../../redux/constants/paymentConstants";
import Popup from "../../core/Popup";
import PaymentHistoryView from "../../core/PaymentHistoryView";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { PAYMENT_LIST_RESET } from "../../redux/constants/paymentConstants";
import { listPaymentByParam } from "../../redux/actions/paymentActions";
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

// Get the current date
const currentDate = new Date();

// Calculate the start date as 3 days earlier than the current date
const startDate = new Date();
startDate.setDate(currentDate.getDate() - 3);

// Format the dates to YYYY-MM-DD
const formatDate = (date) => date.toISOString().split("T")[0];


const initialValues = {
  startDate: formatDate(startDate), // Start date is 3 days earlier
  endDate: formatDate(currentDate), // End date is the current date
  customerId: 0,// False entry to not to load prevou data that edidted
  isInvoice: true,
  employee: {
    id: "",
    label: "",
  },
  paymentReference: "0",
  paymentMode: {
    id: "",
    label: "",
  },
  invoiceCheque: {
    id: "",
    label: "",
  },
  customer: {
    id: "",
    label: "",
  },
  moneyReceiptNo: "",
  invoiceNumber: "",
  paidAmount: "",
  balanceAmount: "",
  invoiceAmount: "",
  receiveAmount: "",
  adjustedAmount: "",
  adjustedAmountShow: "",
  paymentDate: [date.getFullYear(), mnth, day].join("-"),
  paymentNote: "",

  chequeDate: [date.getFullYear(), mnth, day].join("-"),
  chequeActionDate: [date.getFullYear(), mnth, day].join("-"),
  chequeNumber: "",
  chequeAmount: "",
  bank: {
    id: "",
    label: "",
  },

  chequeStatus: {
    id: 2,
    label: "Cleared",
  },
};

const EditPayment = ({ match, history }) => {
  const paymentId = match.params.id;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  // Define state for the loading spinner
 const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [checked, setChecked] = useState(true);
  // const { values, setValues, handleChange } = useForm(initialValues);

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const invoiceChequeList = useSelector((state) => state.invoiceChequeList);
  const {
    loading: loadingInvoiceCheque,
    error: errorInvoiceCheque,
    invoicecheques,
  } = invoiceChequeList;

  const paymentModeList = useSelector((state) => state.paymentModeList);
  const {
    loading: loadingPaymentMode,
    error: errorPaymentMode,
    paymentmodes,
  } = paymentModeList;

  const employeeList = useSelector((state) => state.employeeList);
  const {
    loading: loadingEmployee,
    error: errorEmployee,
    employees,
  } = employeeList;

  const chequeTypeList = useSelector((state) => state.chequeTypeList);
  const {
    loading: loadingChequeType,
    error: errorChequeType,
    chequeTypes,
  } = chequeTypeList;

  const invoiceDetailsReducer = useSelector(
    (state) => state.invoiceDetailsReducer
  );
  const {
    loading: loadingInvoiceDetailsReducer,
    error: errorInvoiceDetailsReducer,
    invoiceSingle,
  } = invoiceDetailsReducer;

  const chequeDetails = useSelector((state) => state.chequeDetails);
  const {
    loading: loadingChequeDetails,
    error: errorChequeDetails,
    chequeSingle,
  } = chequeDetails;

  const paymentHistoryDetails = useSelector(
    (state) => state.paymentHistoryDetails
  );
  const {
    loading: loadingPaymentHistoryDetails,
    error: errorPaymentHistoryDetails,
    paymentHistoryList,
  } = paymentHistoryDetails;

  const bankList = useSelector((state) => state.bankList);
  const { loading: loadingBanks, error: errorBanks, banks } = bankList;

  const chequeStatusList = useSelector((state) => state.chequeStatusList);
  const {
    loading: loadingChequeStatus,
    error: errorChequeStatus,
    chequestatuss,
  } = chequeStatusList;

  const channelList = useSelector((state) => state.channelList);
  const { loading: loadingChannels, error, channels } = channelList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const designationList = useSelector((state) => state.designationList);
  const {
    loading: loadingDesignations,
    error: errorDesignations,
    designations,
  } = designationList;

  const paymentCreate = useSelector((state) => state.paymentCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    payment: createdPayment,
  } = paymentCreate;

  const paymentDetails = useSelector((state) => state.paymentDetails);
  const {
    loading: loadingPaymentDetails,
    error: errorPaymentDetails,
    paymentSingle,
  } = paymentDetails;

  const paymentUpdate = useSelector((state) => state.paymentUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = paymentUpdate;
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const formik = useFormik({
    initialValues,
    validationSchema: PaymentSchema,
    onSubmit: (values, action) => {
      dispatch(
        updatePayment({
          // paymentSystemId: paymentId,
          // paymentCustomerId: values.customer.id,
          // chequeAmount:
          //   values.paymentReference === "0" || values.paymentReference === "2" || values.paymentReference === "3"
          //     ? null
          //     : values.receiveAmount,
          // paymentAmount:
          //   values.paymentReference === "0" || values.paymentReference === "2" || values.paymentReference === "3"
          //     ? values.receiveAmount
          //     : null,
          // paymentRelatedJobId:
          //   values.paymentReference === "0" ? values.invoiceCheque.id : null,
          // paymentAdjustedAmount:
          //   values.paymentReference === "0"
          //     ? values.adjustedAmount === ""
          //       ? null
          //       : values.adjustedAmount
          //     : null,
          // paymentMethodId: values.paymentMode.id,
          // paymentDate: values.paymentDate,
          // paymentCollectedBy: values.employee.id,
          // // moenyRecepitNo:
          // //   values.moneyReceiptNo === "" ? null : values.moneyReceiptNo,
          // moenyRecepitNo:
          //   values.paymentReference === "3"
          //     ? ""
          //     : values.moneyReceiptNo,

          // paymentChequeId:
          //   values.paymentReference === "0" || values.paymentReference === "2" || values.paymentReference === "3"
          //     ? null
          //     : values.invoiceCheque.id,
          // paymentNote: values.paymentNote,
          // createdBy: values.createdBy,
          // createdDate: values.createdDate,
          //  modifiedBy: values.modifiedBy,
          //  modifiedDate: values.modifiedDate,
          //  paymentCollectedChannelId:values.paymentCollectedChannelId,
          // chequeBeneficiaryBankId:
          //   values.paymentReference === "0" || values.paymentReference === "2"
          //     ? null
          //     : values.bank.id,
          // chequeStatusId:
          //   values.paymentReference === "0" || values.paymentReference === "2" || values.paymentReference === "3"
          //     ? null
          //     : values.chequeStatus.id,
          // chequeActionDate:
          //   values.paymentReference === "0" || values.paymentReference === "2" || values.paymentReference === "3"
          //     ? null
          //     : values.chequeActionDate,
          paymentSystemId: paymentId,
          paymentCustomerId: values.customer.id,
          chequeAmount: ["0", "2", "3"].includes(values.paymentReference) ? null : values.receiveAmount,
          paymentAmount: ["0", "2", "3", "7"].includes(values.paymentReference)
            ? values.receiveAmount
            : null,
          paymentRelatedJobId: values.paymentReference === "0" ? values.invoiceCheque.id : null,
          paymentAdjustedAmount:
            ["0", "4", "5", "6"].includes(values.paymentReference) &&
            values.adjustedAmount !== ""
              ? values.adjustedAmount
              : null,
          paymentMethodId:
            values.paymentReference === "4"
              ? 9
              : values.paymentReference === "5"
              ? 10
              : values.paymentReference === "6"
              ? 11
              : values.paymentReference === "7"
              ? 12
              : values.paymentMode.id,
          paymentDate: values.paymentDate,
          paymentCollectedBy: values.employee.id,
          moenyRecepitNo: ["3", "4", "5", "6", "7"].includes(values.paymentReference)
            ? ""
            : values.moneyReceiptNo,
          paymentChequeId: ["0", "2", "3", "4", "5", "6", "7"].includes(values.paymentReference)
            ? null
            : values.invoiceCheque.id,
          paymentNote: values.paymentNote,
          chequeBeneficiaryBankId: ["0", "2", "4", "5", "6", "7"].includes(values.paymentReference)
            ? null
            : values.bank.id,
          chequeStatusId: ["0", "2", "3", "4", "5", "6", "7"].includes(values.paymentReference)
            ? null
            : values.chequeStatus.id,
          chequeActionDate: ["0", "2", "3", "4", "5", "6", "7"].includes(values.paymentReference)
            ? null
            : values.chequeActionDate,
        })
      ).then((result) => {
        if (result?.title === "One or more validation errors occurred.") {
          action.setSubmitting(false);
        } else if (result?.errMsg) {
          if (result.errMsg == "Customer Already Exist") {
            action.setFieldError("errorClientName", result.errMsg);
          } else if (result.errMsg == "Contact Number Already Exist") {
            action.setFieldError("errorClientPhone", result.errMsg);
          }

          action.setSubmitting(false);
        } else {
          // action.resetForm();
          setIsOpen(false);
          setOpenPopup({
            ...openPopup,
            isOpen: false,
            title: "",
            subTitle: "",
          });
          dispatch({ type: CHEQUE_DETAILS_RESET });
          dispatch({ type: INVOICE_DETAILS_RESET });
          dispatch({ type: PAYMENT_HISTORY_DETAILS_RESET });
         

          setOrder("0");
          //dispatch({ type: PAYMENT_LIST_RESET });
          dispatch(listPaymentByParam(null, initialValues)); 
          history.push("/payment/list");
        }
      });
    },
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(listPaymentDetails(paymentId));
      dispatch(listCustomersForSignup());
      dispatch(listInvoiceCheckKeyValue(order));
      dispatch(listPaymentMode(2));
      dispatch(listEmployeesForDropdown());
      //dispatch(listChequeStatus());
      dispatch(listBank());
      //dispatch(listChequeType());
      //dispatch(listDesignation());
      //dispatch(listChannel());
      //dispatch(listDeparment());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: `/payment/${paymentId}/edit` } },
      };
      history.push(location);
    }
  }, [paymentId, order, dispatch, history, userInfo]);

  
  const changeValues = () => {
    if (paymentSingle && Object.keys(paymentSingle).length > 0) {
     // console.log("Loaded Payment Data:", paymentSingle);
     console.log("paymentSingle:", paymentSingle); // Debugging
      const formatDate = (date) =>
        date ? new Date(date).toISOString().split("T")[0] : "";
  
      const paymentReference = (() => {
        if ([4, 7, 8].includes(paymentSingle.paymentMethodId)) return "3";
        if (paymentSingle.paymentMethodId === 9) return "4";
        if (paymentSingle.paymentMethodId === 10) return "5";
        if (paymentSingle.paymentMethodId === 11) return "6";
        if (paymentSingle.paymentMethodId === 12) return "7";
        return paymentSingle.paymentChequeId === null
          ? paymentSingle.paymentRelatedJobId === null
            ? "2"
            : "0"
          : "1";
      })();
      formik.setFieldValue("paymentReference", paymentReference);
      setOrder(paymentReference); // Set order here as it’s used in JSX conditions

      // Update form values
      formik.setFieldValue("paymentReference", paymentReference);
      formik.setFieldValue("paymentDate", formatDate(paymentSingle.paymentDate));
      formik.setFieldValue("chequeDate", formatDate(paymentSingle.chequeDate));
      formik.setFieldValue("chequeActionDate", formatDate(paymentSingle.chequeActionDate));
      formik.setFieldValue("moneyReceiptNo", paymentSingle.moenyRecepitNo || "");
      formik.setFieldValue("paymentNote", paymentSingle.paymentNote || "");
  
      formik.setFieldValue("paymentMode", {
        id: paymentSingle.paymentMethodId || "",
        label: paymentSingle.paymentMethodName || "",
      });
      formik.setFieldValue("invoiceCheque", {
        id:
          paymentSingle.paymentChequeId === null
            ? paymentSingle.paymentRelatedJobId
            : paymentSingle.paymentChequeId || "",
        label:
          paymentSingle.paymentChequeId === null
            ? paymentSingle.soSystemNo
            : paymentSingle.chequeNumber || "",
      });
     
      formik.setFieldValue("customer", {
        id: paymentSingle.paymentCustomerId || "",
        label: paymentSingle.customerName || "",
      });
  
      formik.setFieldValue("employee", {
        id: paymentSingle.paymentCollectedBy || "",
        label:
          paymentSingle.empFirstName &&
          paymentSingle.empLastName &&
          paymentSingle.employeeCode
            ? `${paymentSingle.empFirstName} ${paymentSingle.empLastName}-${paymentSingle.employeeCode}`
            : "",
      });
  
      formik.setFieldValue("receiveAmount", paymentSingle.paymentAmount || "");
      formik.setFieldValue("adjustedAmount", paymentSingle.paymentAdjustedAmount || "");
      formik.setFieldValue("adjustedAmountShow", paymentSingle.paymentAdjustedAmount || "");
    } else {
      console.error("Payment Data is Empty or Undefined.");
    }
  };
  
  useEffect(() => {
    changeValues();
  }, [paymentSingle]);



useEffect(() => {
  if (formik.values.paymentReference) {
    setOrder(formik.values.paymentReference);
  }
}, [formik.values.paymentReference]);

  const changeInvoiceValues = () => {
   
    if (JSON.stringify(invoiceSingle) !== "{}" && invoiceSingle !== undefined) {
      formik.setFieldValue("invoiceNumber", invoiceSingle.soSystemNo || "");
      formik.setFieldValue("invoiceAmount", invoiceSingle.sobFinalBill);
      formik.setFieldValue("paidAmount", invoiceSingle.paidAmount);
      formik.setFieldValue("balanceAmount", invoiceSingle.balance);
      formik.setFieldValue("adjustedAmountShow", invoiceSingle.adjustedAmount);
      formik.setFieldValue("customer", {
        id: invoiceSingle.customerId || "",
        label: invoiceSingle.customerName || "",
      });
    } else {
      console.error("Payment Data is Empty or Undefined.");
    }
  };

  useEffect(() => {
    //console.log("invoiceSingle:", invoiceSingle); // Debugging
    changeInvoiceValues();
  }, [invoiceSingle]);

  const changeChequeValues = () => {
    if (JSON.stringify(chequeSingle) !== "{}" && chequeSingle !== undefined) {
      var date = new Date(chequeSingle.chequeCollectionDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeCollectionDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeExpireDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeExpireDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequePlacementDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequePlacementDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeActionDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeActionDate = [date.getFullYear(), mnth, day].join("-");

      formik.setFieldValue("chequeCollectionDate", chequeCollectionDate || "");
      formik.setFieldValue("chequeDate", chequeDate || "");
      formik.setFieldValue("chequeExpireDate", chequeExpireDate || "");
      formik.setFieldValue("chequePlacementDate", chequePlacementDate || "");
      formik.setFieldValue("chequeActionDate", chequeActionDate || "");
      formik.setFieldValue("chequeNumber", chequeSingle.chequeNumber || "");
      formik.setFieldValue("chequeAmount", chequeSingle.chequeAmount || "");
      formik.setFieldValue("chequeType", {
        id: chequeSingle.chequeMethodInfoId || "",
        label: chequeSingle.paymentMethodName || "",
      });
      formik.setFieldValue("customer", {
        id: chequeSingle.chequeCustomerId || "",
        label: chequeSingle.customerName || "",
      });
      formik.setFieldValue("bank", {
        id: chequeSingle.chequeBeneficiaryBankId || "",
        label: chequeSingle.beneficiaryBankName || "",
      });
      formik.setFieldValue("issuerBank", {
        id: chequeSingle.chequeIssuerBankId || "",
        label: chequeSingle.chequeBankName || "",
      });
      formik.setFieldValue("chequeStatus", {
        id: 2 || "",
        label: "Cleared" || "",
      });
    }
  };

  useEffect(() => {
    changeChequeValues();
  }, [chequeSingle]);

  // const handleSubmit = async (values) => {
  //   try {
  //     await dispatch(updatePayment(values)); // Update payment
  //     await dispatch(listPaymentByParam(null, initialValues)); // Fetch updated list
  //     history.push("/payment/list"); // Redirect to the list page
  //   } catch (error) {
  //     console.error("Error updating payment:", error);
  //   }
  // };
  // const channelChange = useCallback((e, v) => {
  //   formik.setFieldValue("channel", {
  //     id: v?.id || "",
  //     label: v?.label || "",
  //   });
  // });

  const designationChange = useCallback((e, v) => {
    formik.setFieldValue("designation", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const departmentChange = useCallback((e, v) => {
    formik.setFieldValue("department", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const employeeChange = useCallback((e, v) => {
    formik.setFieldValue("employee", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const chequeTypeChange = useCallback((e, v) => {
    formik.setFieldValue("chequeType", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const paymentModeChange = useCallback((e, v) => {
    formik.setFieldValue("paymentMode", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const invoiceChequeChange = useCallback((e, v) => {
    console.log("Selected Invoice Cheque:", v); // Debugging
    formik.setFieldValue("invoiceCheque", {
      id: v?.id || "",
      label: v?.label || "",
    });

    if (order === "0") {
      dispatch(listInvoiceDetails(v?.id));
    } else if (order === "1") {
      dispatch(listChequeDetails(v?.id));
    }
  });
  

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const chequeStatusChange = useCallback((e, v) => {
    formik.setFieldValue("chequeStatus", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const bankChange = useCallback((e, v) => {
    formik.setFieldValue("bank", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const issuerBankChange = useCallback((e, v) => {
    formik.setFieldValue("issuerBank", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const invoiceChequeCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const customerOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const chequeStatusOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const bankOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const closeModal = (event) => {
    setIsOpen(false);
  };

  
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Payments"
        subtitle="Edit this Payment"
      />
      
     <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
                   {/* {Common feild to be loaded} */}
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingEmployee ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={employeeChange}
                value={formik.values.employee}
                options={employees !== undefined ? employees : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Collected By"
                    name="employee"
                    error={
                      formik.errors.employee !== undefined
                        ? formik.errors.employee.id
                        : ""
                    }
                    touched={
                      formik.touched.employee !== undefined
                        ? formik.touched.employee
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              
         {/* {Common feild to be loaded} */}  
         
              <Select
                variant="outlined"
                size="small"
                label="Payment Type"
                name="paymentReference"
                error={formik.errors.paymentReference}
                touched={formik.touched.paymentReference}
                value={formik.values.paymentReference}
                onChange={(e) => {
                  formik.setFieldValue("invoiceCheque", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("paymentMode", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("customer", {
                    id: "",
                    label: "",
                  });
                  //formik.setFieldValue("invoiceNumber", "");
                  formik.setFieldValue("paidAmount", "");
                  formik.setFieldValue("balanceAmount", "");
                  formik.setFieldValue("adjustedAmount", "");
                  formik.setFieldValue("adjustedAmountShow", "");
                  formik.setFieldValue("invoiceAmount", "");
                  formik.setFieldValue("receiveAmount", "");
                  formik.setFieldValue("paymentNote", "");
                  formik.setFieldValue(
                    "chequeDate",
                    [date.getFullYear(), mnth, day].join("-")
                  );
                  formik.setFieldValue(
                    "chequeActionDate",
                    [date.getFullYear(), mnth, day].join("-")
                  );
                  formik.setFieldValue("chequeNumber", "");
                  formik.setFieldValue("chequeAmount", "");
                  formik.setFieldValue("bank", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("chequeStatus", {
                    id: "",
                    label: "",
                  });
                  setIsOpen(false);
                  setOpenPopup({
                    ...openPopup,
                    isOpen: false,
                    title: "",
                    subTitle: "",
                  });
                  dispatch({ type: CHEQUE_DETAILS_RESET });
                  dispatch({ type: INVOICE_DETAILS_RESET });
                  dispatch({ type: PAYMENT_HISTORY_DETAILS_RESET });
                  formik.handleChange(e);
                
                  formik.setFieldValue(
                    "isInvoice",
                    String(e.target.value) === "0" ? true : false
                  );

                  setOrder(e.target.value);
                }}
                onBlur={formik.handleBlur}
                options={[
                  { id: "0", title: "Invoice" },
                  { id: "1", title: "Cheque" },
                  { id: "2", title: "Open" },
                  { id: "3", title: "Transfer" },
                  { id: "4", title: "AIT" },
                  { id: "5", title: "VAT" },
                  { id: "6", title: "Credit Adjustment" },
                  { id: "7", title: "LC Payment" },
                ]}
              />
              <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingCustomer ? true : false}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={customerOptionCheck}
                  onChange={customerChange}
                  value={formik.values.customer}
                  options={customers !== undefined ? customers : []}
                  onOpen={formik.handleBlur}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <Input
                      label="Customer Name"
                      name="customer"
                      error={
                        formik.errors.customer !== undefined
                          ? formik.errors.customer.id
                          : ""
                      }
                      touched={
                        formik.touched.customer !== undefined
                          ? formik.touched.customer
                          : ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
                <Datepicker
                      label="Payment Date"
                      name="paymentDate"
                      value={formik.values.paymentDate}
                      onChange={formik.handleChange}
                    />
             
              {["0", "1", "2", "3"].includes(order) && (
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingPaymentMode}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onChange={paymentModeChange}
                  value={formik.values.paymentMode}
                  options={paymentmodes || []}
                  onOpen={formik.handleBlur}
                  renderInput={(params) => (
                    <Input
                      label="Payment Mode"
                      name="paymentMode"
                      error={
                        formik.errors.paymentMode?.id || ""
                      }
                      touched={
                        formik.touched.paymentMode || ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
              )}
               {["0", "1"].includes(order) && (
              <Autocomplete
              disablePortal
              size="small"
              disabled={loadingInvoiceCheque ? true : false}
              id="combo-box-demo-channel"
              isOptionEqualToValue={invoiceChequeCheck}
              onChange={invoiceChequeChange}
              value={formik.values.invoiceCheque}
              options={invoicecheques !== undefined ? invoicecheques : []}
              onOpen={formik.handleBlur}
              renderInput={(params) => (
                <Input
                  label={order === "0" ? "Invoice No" : "Cheque No"}
                  name="invoiceCheque"
                  error={
                    formik.errors.invoiceCheque !== undefined
                      ? formik.errors.invoiceCheque.id
                      : ""
                  }
                  touched={
                    formik.touched.invoiceCheque !== undefined
                      ? formik.touched.invoiceCheque
                      : ""
                  }
                  {...params}
                  onBlur={formik.handleBlur}
                />
              )}
            />
            
              )}

              {["0", "7", "2", "3"].includes(order) && (
                <Input
                  label="Receive Amount"
                  name="receiveAmount"
                  error={formik.errors.receiveAmount}
                  touched={formik.touched.receiveAmount}
                  value={formik.values.receiveAmount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
              
            {["0", "2"].includes(order) && (
                <Input
                  label="Money Receipt No"
                  name="moneyReceiptNo"
                  error={formik.errors.moneyReceiptNo}
                  touched={formik.touched.moneyReceiptNo}
                  value={formik.values.moneyReceiptNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
              {["3"].includes(order) && (
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingBanks}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={bankOptionCheck}
                  onChange={bankChange}
                  value={formik.values.bank}
                  options={banks || []}
                  onOpen={formik.handleBlur}
                  renderInput={(params) => (
                    <Input
                      label="Beneficiary Bank"
                      name="bank"
                      error={formik.errors.bank?.id || ""}
                      touched={formik.touched.bank || ""}
                      {...params}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
              )}
             
              {["0", "1"].includes(order) && (
                <>
                  <div>
                    <MuiButton
                      size="small"
                      startIcon={
                        loadingPaymentHistoryDetails ? (
                          <CircularProgress size="1rem" color="error" />
                        ) : null
                      }
                      onClick={() => {
                        if (formik.values.invoiceCheque?.id) {
                          dispatch(
                            listPaymentHistoryDetails(formik.values.invoiceCheque.id)
                          ).then((res) => {
                            if (res?.length > 0) {
                              setIsOpen(true);
                            } else {
                              setOpenPopup({
                                ...openPopup,
                                isOpen: true,
                                title: "EMPTY HISTORY!!",
                                subTitle: "No history found!",
                              });
                            }
                          });
                        } else {
                          setOpenPopup({
                            ...openPopup,
                            isOpen: true,
                            title: "NO INVOICE SELECTED!!",
                            subTitle: "Please select an invoice!",
                          });
                        }
                      }}
                    >
                      View
                    </MuiButton>
                  </div>

                  <Input
                    label="Adjusted Amount"
                    name="adjustedAmount"
                    error={formik.errors.adjustedAmount}
                    touched={formik.touched.adjustedAmount}
                    value={formik.values.adjustedAmount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </>
              )}

              {[ "4", "5", "6"].includes(order) && (
                <>
                  <Input
                    label="Adjusted Amount"
                    name="adjustedAmount"
                    error={formik.errors.adjustedAmount}
                    touched={formik.touched.adjustedAmount}
                    value={formik.values.adjustedAmount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{}}
                  />
                </>
              )}
              {[ "1"].includes(order) &&
                JSON.stringify(chequeSingle) !== "{}" &&
                chequeSingle !== undefined && (
                  <>
                    <Autocomplete
                      disablePortal
                      size="small"
                      disabled={loadingBanks ? true : false}
                      id="combo-box-demo-channel"
                      isOptionEqualToValue={bankOptionCheck}
                      onChange={bankChange}
                      value={formik.values.bank}
                      options={banks !== undefined ? banks : []}
                      onOpen={formik.handleBlur}
                      renderInput={(params) => (
                        <Input
                          label="Beneficiary Bank"
                          name="bank"
                          error={
                            formik.errors.bank !== undefined
                              ? formik.errors.bank.id
                              : ""
                          }
                          touched={
                            formik.touched.bank !== undefined
                              ? formik.touched.bank
                              : ""
                          }
                          {...params}
                          onBlur={formik.handleBlur}
                        />
                      )}
                    />
                    <Datepicker
                      label="Cheque Action Date"
                      name="chequeActionDate"
                      value={formik.values.chequeActionDate}
                      onChange={formik.handleChange}
                    />
                  </>
                )}
                <Input
                  label="Payment Note"
                  name="paymentNote"
                  error={formik.errors.paymentNote}
                  touched={formik.touched.paymentNote}
                  value={formik.values.paymentNote}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiple
                />
            </Grid>

            <Grid item xs={12} md={6}>
              {[ "0"].includes(order) &&
                JSON.stringify(invoiceSingle) !== "{}" &&
                invoiceSingle !== undefined && (
                  <>
                    <Input
                      label="Invoice Number"
                      name="invoiceNumber"
                      error={formik.errors.invoiceNumber|| ""}
                      touched={formik.touched.invoiceNumber|| ""}
                      value={formik.values.invoiceNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Invoice Amount"
                      name="invoiceAmount"
                      error={formik.errors.invoiceAmount}
                      touched={formik.touched.invoiceAmount}
                      value={formik.values.invoiceAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="paid Amount"
                      name="paidAmount"
                      error={formik.errors.paidAmount}
                      touched={formik.touched.paidAmount}
                      value={formik.values.paidAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Adjusted Amount"
                      name="adjustedAmountShow"
                      error={formik.errors.adjustedAmountShow}
                      touched={formik.touched.adjustedAmountShow}
                      value={formik.values.adjustedAmountShow}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Balance Amount"
                      name="balanceAmount"
                      error={formik.errors.balanceAmount}
                      touched={formik.touched.balanceAmount}
                      value={formik.values.balanceAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                  </>
                )}

              {[ "1"].includes(order) &&
                JSON.stringify(chequeSingle) !== "{}" &&
                chequeSingle !== undefined && (
                  <>
                    <Input
                      label="Cheque Number"
                      name="chequeNumber"
                      error={formik.errors.chequeNumber}
                      touched={formik.touched.chequeNumber}
                      value={formik.values.chequeNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Cheque Amount"
                      name="chequeAmount"
                      error={formik.errors.chequeAmount}
                      touched={formik.touched.chequeAmount}
                      value={formik.values.chequeAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Datepicker
                      label="Cheque Date"
                      name="chequeDate"
                      value={formik.values.chequeDate}
                      onChange={formik.handleChange}
                      disabled
                    />
                  </>
                )}
                
            </Grid>

           
            <Grid item xs={12} md={12}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                
                <MuiButton
                  endIcon={
                    loadingCreate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Submit
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    // console.log(formik);
                    formik.resetForm();
                    setChecked(true);
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/payment/list",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display:
            loadingEmployee
              
                ? ""
                : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
      {JSON.stringify(paymentHistoryList) !== "[]" &&
        paymentHistoryList !== undefined && (
          <PaymentHistoryView
            showModal={isOpen}
            closeModal={closeModal}
            info={paymentHistoryList}
          />
        )}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
  
};

export default withRouter(EditPayment);
